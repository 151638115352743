export const CLOSE_STORY_MODAL = 'CLOSE_MODAL';
export const OPEN_STORY_MODAL = 'OPEN_MODAL';
export const TOGGLE_SHOW_MODAL = 'TOGGLE_SHOW_MODAL';
export const TOGGLE_CONTROL_PANEL = 'TOGGLE_CONTROL_PANEL';
export const TOGGLE_OBJECTS_PANEL = 'TOGGLE_OBJECTS_PANEL';
export const CONTROL_PANEL_DEVICE_ID = 'CONTROL_PANEL_DEVICE_ID';
export const CONTROL_PANEL_GROUP = 'CONTROL_PANEL_GROUP';
export const SET_SCREEN_WIDTH = 'SET_SCREEN_WIDTH';
export const SET_MODAL_TITLE = 'SET_MODAL_TITLE';
export const SET_MODAL_INPUT = 'SET_MODAL_INPUT';
export const SET_MODAL_INPUT_TYPE = 'SET_MODAL_INPUT_TYPE';
export const SET_OBJECTS_VIEW = 'SET_OBJECTS_VIEW';
export const SET_GO_TO_APP_MODAl_TYPE = 'SET_GO_TO_APP_MODAl_TYPE';
export const TOGGLE_DND_ACTIVE = 'TOGGLE_DND_ACTIVE';
export const TOGGLE_FAST_COMMAND_PANEL = 'TOGGLE_FAST_COMMAND_PANEL';
export const TOGGLE_FAST_COMMAND_PANEL_TYPE = 'TOGGLE_FAST_COMMAND_PANEL_TYPE';
export const SET_MODAL_DELETE_TITLE = 'SET_MODAL_DELETE_TITLE';
export const SET_MODAL_DELETE_TEXT = 'SET_MODAL_DELETE_TEXT';
export const SET_MODAL_DELETE_CONFIRM = 'SET_MODAL_DELETE_CONFIRM';
export const SET_MODAL_COMMON_MESSAGE_TITLE = 'SET_MODAL_COMMON_MESSAGE_TITLE';
export const SET_MODAL_COMMON_MESSAGE_TEXT = 'SET_MODAL_COMMON_MESSAGE_TEXT';
export const SET_MODAL_DEFAULT_MESSAGE_TITLE = 'SET_MODAL_DEFAULT_MESSAGE_TITLE';
export const SET_MODAL_DEFAULT_MESSAGE_TEXT = 'SET_MODAL_DEFAULT_MESSAGE_TEXT';
export const SET_MODAL_DEFAULT_MESSAGE_ACTION = 'SET_MODAL_DEFAULT_MESSAGE_ACTION';
export const SET_MODAL_DEFAULT_MESSAGE_STATUS = 'SET_MODAL_DEFAULT_MESSAGE_STATUS';
export const SET_MODAL_COMMON_MESSAGE_CONFIRM = 'SET_MODAL_COMMON_MESSAGE_CONFIRM';
export const SET_MODAL_ADDITIONAL_MODE_TITLE = 'SET_MODAL_ADDITIONAL_MODE_TITLE';
export const SET_MODAL_ADDITIONAL_MODE_TEXT = 'SET_MODAL_ADDITIONAL_MODE_TEXT';
export const SET_MODAL_ADDITIONAL_MODE_ICON = 'SET_MODAL_ADDITIONAL_MODE_ICON';
export const SET_STORIES_LIST = 'SET_STORIES_LIST';
export const SET_CURRENT_STORY_ITEMS_INDEX = 'SET_CURRENT_STORY_ITEMS_INDEX';
export const SET_STORY_WATCHED = 'SET_STORY_WATCHED';
export const SET_LOADED_STORY = 'SET_LOADED_STORY';
export const SET_SERVICE_FETCHED_STATUS = 'SET_SERVICE_FETCHED_STATUS';
export const SET_PANEL_FROM_GROUP = 'SET_PANEL_FROM_GROUP';
export const SET_MODAL_OBJECT = 'SET_MODAL_OBJECT';
export const SET_MODAL_TYPE_OF_DELETE = 'SET_MODAL_TYPE_OF_DELETE';
export const SET_MODAL_DELETE_DATA = 'SET_MODAL_DELETE_DATA';
export const SET_MODAL_CONFLICT_RESOLVE_DATA = 'SET_MODAL_CONFLICT_DATA';
export const SET_MODAL_SHOW_COUNT = 'SET_MODAL_SHOW_COUNT';
export const FORCE_LOCAL_LOADING_CARD_PLACE_ID = 'FORCE_LOCAL_LOADING_CARD_PLACE_ID';
export const SET_VERSIONS = 'SET_VERSIONS';
export const TOGGLE_SCHEDULE_PANEL = 'TOGGLE_SCHEDULE_PANEL';
export const SET_SCHEDULE_ACTIVE_ID = 'SET_SCHEDULE_ACTIVE_ID';
export const SET_CAPTCHA_VISIBILITY = 'SET_CAPTCHA_VISIBILITY';
export const SET_CAPTCHA_CALLBACK = 'SET_CAPTCHA_CALLBACK';
export const SET_CAPTCHA_CANCEL_CALLBACK = 'SET_CAPTCHA_CANCEL_CALLBACK';
export const SET_CAPTCHA_HEADER = 'SET_CAPTCHA_HEADER';
