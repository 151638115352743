
import { mapActions, mapGetters } from "vuex";
import Logo from "@/assets/images/climat-online-logo.svg";
import LogoEn from "@/assets/images/climat-online-logo-en.svg";
import Arrow from "@/assets/images/arrow-right.svg";
import AppButton from "@/components/Buttons/AppButton.vue";

export default {
  name: "ClimateOnlinePayment",
  components: {
    Logo,
    LogoEn,
    Arrow,
    AppButton,
  },
  props: {
    climateOnlineInfo: {
      type: Object,
      default: () => {},
    },
    onBuyClick: {
      type: Function,
      default: () => {},
    },
    onPromocodeClick: {
      type: Function,
      default: () => {},
    },
    isSubmitDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    servicePrice: "",
    serviceCurrency: "",
  }),
  computed: {
    ...mapGetters({
      getTranslations: "localization/getTranslations",
      getLang: "localization/getLang",
      panelDeviceId: "system/getPanelDeviceId",
    }),
  },
  mounted() {
    this.getClimateOnlinePrice({
      deviceId: this.panelDeviceId,
      onClose: () => {
        this.$modal.hide("climateOnlineModal");
      },
    }).then((price) => {
      this.servicePrice = price.price;
      this.serviceCurrency = price.currency;
    });
  },
  methods: {
    ...mapActions({
      getClimateOnlinePrice: "deviceFunctions/getClimateOnlinePrice",
    }),
  },
};
