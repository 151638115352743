
import { mapGetters } from 'vuex';

const SUPPORTED_LANGUAGES = ['ru', 'en', 'be', 'kk', 'tt', 'uk', 'uz', 'tr'];
const DEFAULT_LANGUAGE = 'en';

export default {
  name: 'YandexCaptcha',
  data: () => ({
    widgetId: null,
  }),
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      getLang: 'localization/getLang',
      getTranslations: 'localization/getTranslations',
      getCaptchaVisibility: 'system/getCaptchaVisibility',
      getCaptchaCallback: 'system/getCaptchaCallback'
    }),
  },
  mounted() {
    this.addCaptchaScript();
    setTimeout(() => {
      this.addCaptcha();
    }, 500);
    // setTimeout(() => {
    //   // console.log('###RREFF', this.$refs.yaCaptcha);

    //   window.smartCaptcha.reset(this.widgetId);
    // }, 10000);
  },
  methods: {
    addCaptchaScript() {
      const captchaScript = document.createElement('script');
      captchaScript.setAttribute('src', 'https://smartcaptcha.yandexcloud.net/captcha.js');
      document.head.appendChild(captchaScript);
    },
    addCaptcha() {
      this.widgetId = window.smartCaptcha.render(this.$refs.yaCaptcha, {
        sitekey: process.env.SMARTCAPTCHA_KEY,
        hl: SUPPORTED_LANGUAGES.includes(this.getLang) ? this.getLang : DEFAULT_LANGUAGE,
        callback: this.callbackCaptcha,
      });
    },
    callbackCaptcha(token) {
      this.$store.commit('system/SET_CAPTCHA_HEADER', token);

      this.getCaptchaCallback();

      this.$store.commit('system/SET_CAPTCHA_VISIBILITY', false);
      this.$store.commit('system/SET_CAPTCHA_CALLBACK', () => {});
      setTimeout(() => {
        this.$store.commit('system/SET_CAPTCHA_HEADER', null);
      }, 200);
    }
  },
};
